import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
// import Loader from '../components/Loader';
import Feature from '../components/Feature';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import Signup from '../components/Signup';
import 'normalize.css';
import '../styles/WelcomePage.scss';
import pig from '../images/pig.svg';
import trophy from '../images/trophy.svg';
import newsletter from '../images/newsletter.svg';
import gift from '../images/gift.svg';

const testimonials = [
  {
    key: 0,
    content: 'I needed this today more than you will ever know',
    name: '- Justin'
  },
  {
    key: 1,
    content: 'I always love getting these emails, but this one couldn\'t have come at a better time! Thank you!! I always look forward to seeing these pop-up in my inbox.',
    name: '- Kristi'
  },
  {
    key: 2,
    content:
      'Not sure if you are getting this but thank you for these emails. Being a business owner during these times is very challenging',
    name: '- Rebecca'
  }
];
class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header />
        <div className="welcome-page" id="top">
          <div className="hero">
            <div className="row" style={{ flex: 'auto' }}>
              <div className="left">
                <Signup />
                <p style={{ paddingTop: 25 }}>
                </p>
              </div>
              <div className="right">
                <img
                  className="phone"
                  alt="iPhone Preview"
                  src="/images/hero-cropped.png"
                />
              </div>
            </div>
          </div>

          <div className="features row">
            <Feature
              icon={newsletter}
              title="Join 100K Go Getters"
              content="Trusted by Go Getters just like you. We often share our fav quotes, what books we are reading or words of encouragement to help you through a rough day, tips and tricks to stay motivated to achieve your goals."
            />
            <Feature
              icon={gift}
              title="Win Free Prizes"
              content="You can win FREE PRIZES by sharing our motivational newsletter with your friends, family or co-workers. It only takes 5 minutes to get started."
            />
            <Feature
              icon={pig}
              title="Free Forever"
              content="When you sign up today you will never ever ever have to pay for access to the Coffee &amp; Motivation Newsletter. "
            />
            <Feature
              icon={trophy}
              title="Stay Motivated"
              content="Stay motivated to achieve your goals! Our emails are never long or make you feel like someone is preaching to you. Just two friends hanging out helping each other grow. So what are you waiting for? sign up now to get your morning motivation."
            />
          </div>

          <div className="footer">
            <div className="row">
              <h2>#coffeeandmotivation</h2>
              <div className="testimonials">
                <Slider
                  data={testimonials}
                  autoplay
                  fullWidth
                  navigation="dots"
                  delay={7000}
                />
              </div>
              <a href="#top">Sign Up Today</a>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default WelcomePage;

import React from 'react';
import { BrowserRouter as Switch, Route } from 'react-router-dom';
// import Signup from './Signup';
// import Prices from './Prices';
// import Account from './Account';
import WelcomePage from '../pages/WelcomePage';
import PrivacyPolicy from '../pages/PrivacyPolicy';

import '../styles/App.scss';

function App(props) {
  return (
    <Switch>
      <Route exact path="/">
        <WelcomePage />
        {/* <Signup /> */}
      </Route>
      {/* <Route path="/prices">
        <Prices />
      </Route>
      <Route path="/account">
        <Account />
      </Route> */}
      <Route path="/policies-terms-and-conditions">
        <PrivacyPolicy />
      </Route>
    </Switch>
  );
}

export default App;

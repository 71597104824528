import React, { useState } from 'react';
// import { Redirect } from 'react-router-dom';
import PaymentForm from './PaymentForm';
import NumberFormat from 'react-number-format';

function Signup(props) {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [customer, setCustomer] = useState(null); //null

  const handleSubmit = (e) => {
    e.preventDefault();
    // https://stripe.com/docs/api/customers/object
    return fetch('/create-customer', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        name: name,
        phone: phone,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setCustomer(result.customer);
      });
  };

  if (customer) {
    return (
      <PaymentForm customer={customer} />
      // <Redirect
      //   to={{
      //     pathname: '/prices',
      //     state: { customer: customer },
      //   }}
      // />
    );
  } else {
    return (
      <div>
        <h2 style={{ paddingBottom: 25 }}>
          Sign Up For Our Motivational Text Messages
        </h2>
        <p>
          Sign up for our <strong>TEXT MOTIVATIONAL MESSAGES.</strong>{' '}
          Our messages are never long or make you feel like someone is
          preaching to you. Just two friends hanging out helping each
          other grow. So what are you waiting for? sign up now to get
          your morning motivation.
        </p>        
        <div className="antialiased">
          <div className="flex">
            <div className="w-full">
              <form id="signup-form" onSubmit={handleSubmit}>
                <div className="mb-2">                
                  <input
                    className="appearance-none"
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    required
                  />
                </div>                
              <div className="mb-2">
                {/* <input
                  className="appearance-none"
                  id="phone"
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone number"
                  required
                /> */}
                <NumberFormat
                  format="+1 (###) ###-####"
                  placeholder="Phone number"
                  required
                  // allowEmptyFormatting
                  mask="_"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="mb-2">
                <input
                  className="appearance-none"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email address"
                  required
                />
              </div>
              <div className="mb-2">
                <button
                    id="email-submit"
                    type="submit"
                  >
                    <div id="loading" className="hidden">
                      Signing up...
                    </div>
                    <span id="button-text">Send me motivation!
                      <span role="img" aria-label="thumbs_up">
                        {' 👍'}
                      </span>
                    </span>
                  </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
